$(document).on('ready turbolinks:load', function() {
  window.unsavedChanges = 0
  initTooltips()
  disableAddDap()
  initAddDapFields('#assistance_payments')
  fixTableHeader()

  $('body').on('change', 'select, textarea, input', function() {
    $(this).closest('.form-group').find('.error-message').addClass('hidden')
  })

  $(this).on('cocoon:after-insert cocoon:after-remove', '#assistance_payments', function(event, added_dap) {
    disableAddDap()
    initAddDapFields(this)
    if (event.type === 'cocoon:after-insert') initSelect2(added_dap)
  });
});

function disableAddDap() {
  const $dapInputs = $('.dap-input')
  const $addDapButton = $('#add-dap')
  const isSomeEmpty = () => [...$dapInputs].some(dapInput => dapInput.value === '')

  toggleDisable()

  $dapInputs.on('change', toggleDisable)

  function toggleDisable() {
    if (isSomeEmpty()) $addDapButton.attr('disabled', true)
    else $addDapButton.attr('disabled', false)
  }
}

global.initAddDapFields = function (wrapper) {
  const $wrapper = $(wrapper)
  const counter = $wrapper.children('.nested-fields').not((_, item) => item.style.display === 'none').length

  if (counter > 0) $wrapper.removeClass('hidden')
  else $wrapper.addClass('hidden')
}

global.initTooltips = function (selector = '[data-toggle="tooltip"]', options = {}) {
  if (!$.fn.tooltip) return

  global.$tooltips = $(selector).tooltip({
    trigger: 'hover click',
    ...options
  })
}

global.positiveOrNull = function (value) {
  if (value === '') return null;

  const positiveCheck = Math.sign(value);

  return (positiveCheck === -1 || isNaN(positiveCheck)) ? null : value;
}

global.focusContribution = function (year) {
  $(`.contribution-input[data-year='${year}']`)[0]?.focus()
}

global.fixTableHeader = function (selector = '.table') {
  const $table = $(selector)
  if (!$table.length) return

  const $tHead = $table.find('.table__header--fixed')
  const tableHeight = $table.innerHeight()
  const tHeadHeight = $tHead.outerHeight()

  $(window).on('scroll', fix)

  fix()

  function fix() {
    let top = Math.min(Math.max(0, $(window).scrollTop() - $table.offset().top), tableHeight - tHeadHeight)
    $tHead.css({ transform: `translateY(${top}px)` })
  }
}

global.loader = function () {
  const $body = $('body')

  return {
    start: () => $body.addClass('loading'),
    stop: () => $body.removeClass('loading')
  }
}
