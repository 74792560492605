const toggleIncomeRowVisibility = (incomeGroup, selectedOption) => {
  if (selectedOption === '[1, 2]') {
    incomeGroup.find('.income').removeClass('hidden');
  } else {
    incomeGroup.find('.income').addClass('hidden');
    incomeGroup.find('.income-field').val('');
  }
};

$(document).on('ready turbolinks:load', function() {
  $('body').on('click', '#show-contribution-input', function() {
    $('.contribution-input').removeClass('hidden');
    $('#submit-contributions').removeClass('hidden');
    $('#hide-contribution-input').removeClass('hidden');
    $('#show-contribution-input').addClass('hidden');
    $('.contribution-value').addClass('hidden');
  });

  $('body').on('click', '#submit-contributions', function() {
    const form = $('#contributions-form')[0];
    Rails.fire(form, 'submit');
  });

  $('body').on('click', '#hide-contribution-input', function() {
    $('.contribution-input').addClass('hidden');
    $('#submit-contributions').addClass('hidden');
    $('#hide-contribution-input').addClass('hidden');
    $('#show-contribution-input').removeClass('hidden');
    $('.contribution-value').removeClass('hidden');
  });

  $('body').on('click', '.step-edit-account', function() {
    if(window.unsavedChanges == 1) {
      return confirm('You have some unsaved changes. Would you like to proceed without saving?')
    }
  });

  $('.edit_account').on('change', 'input, select', function() {
    window.unsavedChanges = 1;
  });

  $('body').on('select2:select', '.income-select', function(e) {
    e.stopPropagation();
    e.preventDefault();

    const selectedOption = $(this).val();
    const selectedAgent = $(this).closest('.wizard-income-group').data('agent');
    const nextContainers = [...$(this).closest('.wizard-income-group').nextAll(`.wizard-income-group[data-agent="${selectedAgent}"]`)];
    nextContainers.forEach(async (el) => {
      const selector = $(el).find('.income-select');
      selector.val(selectedOption).trigger('change');
      toggleIncomeRowVisibility($(el), selectedOption);
    })
    toggleIncomeRowVisibility($(this).closest('.wizard-income-group'), selectedOption);
  });

  $('body').on('change', 'input[data-validate-contribution="true"]', function (e) {
    e.preventDefault();
    const value = $(this).val();
    const validValue = positiveOrNull(value);
    const totalContribution = +$(this).data('total-contribution');
    const currentContribution = +$(this).data('current-contribution');

    $(this).val(validValue);

    const contributionLimit = 200000 - (totalContribution - currentContribution);

    if (value > contributionLimit) {
      alert(`All personal contributions combined may not exceed $200,000 over the beneficiary’s lifetime. Please adjust your contribution amounts. Max allowed contribution for this year is: ${contributionLimit}`);
      $(this).val(currentContribution);
      let input = $(this);
      setTimeout(() => { this.select() }, 0);
    } else if ($(this).hasClass('contribution-input')) {
      const contribution = $(this).val();
      const year = $(this).data('year');
      $.ajax({
        url: $('#contributions-form')[0].action,
        method: 'POST',
        dataType: 'script',
        data: {
          preferred_contribution: { contribution, year }
        },
        beforeSend: loader().start,
        success: loader().stop
      }).done(function(){
        $(document.activeElement)?.select();
      });
    }
  });

  $('body').on('click', '#modify-contributions-btn', async function (e) {
    await $.ajax({
      url: $(this).data('url'),
      method: 'PATCH',
      dataType: 'script',
      data: {
        account: { custom_schedule_enabled: true }
      },
    })

    $('.modify-button-container').addClass('d-none');

    setTimeout(() => { $('#contributions-form :input:enabled:visible:first').focus() }, 500);
  });
});

global.changeContribution = function (url) {
  if (!url) return

  $.ajax({
    url,
    method: 'GET',
    dataType: 'script'
  })
}
