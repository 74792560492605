import { fillColorsTop, initLegend } from './chart'

const currency = number => new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(number).replace(/\.\d+/, '')

const baseOptions = () => ({
  legendCallback: initLegend,
  legend: { display: false },
  hover: { intersect: false },
  maintainAspectRatio: false,
  tooltips: {
    position: 'nearest',
    xPadding: 20,
    yPadding: 10,
    enabled: false,
    custom: customTooltip,
  },
  scales: {
    xAxes: [{
      gridLines: { display: false },
      ticks: {
        autoSkip: true,
        autoSkipPadding: 20,
        maxRotation: 0,
        padding: 10,
        min: 0,
        maxTicksLimit: 5,
      }
    }],
    yAxes: [{
      gridLines: {
        color: '#E7E7E7',
        zeroLineColor: '#E7E7E7',
        drawBorder: false,
        drawTicks: false
      },
      ticks: {
        beginAtZero: 0,
        maxTicksLimit: 5,
        padding: 15,
        callback: value => new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(value).replace(/\.\d+/, ''),
      }
    }]
  },
  onClick: false,
  plugins: {
    crosshair: {
      enabled: true,
      color: fillColorsTop[1],
      width: 1,
      dashPattern: [2, 2],
      sync: {
        suppressTooltips: true,
        enabled: false
      },
      zoom: { enabled: false },
      snap: { enabled: true },
    }
  }
})

function customTooltip(tooltipModel) {
  var tooltipEl = document.getElementById('chartjs-tooltip')

  // Create element on first render
  if (!tooltipEl) {
    tooltipEl = document.createElement('div')
    tooltipEl.id = 'chartjs-tooltip'
    document.body.appendChild(tooltipEl)
  }

  // Hide if no tooltip
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = 0
    return
  }

  // Set caret Position
  tooltipEl.classList.remove(...tooltipEl.classList.values())
  if (tooltipModel.yAlign || tooltipModel.xAlign) {
    if (tooltipModel.yAlign) tooltipEl.classList.add(tooltipModel.yAlign)
    if (tooltipModel.xAlign) tooltipEl.classList.add(tooltipModel.xAlign)
  } else {
    tooltipEl.classList.add('no-transform')
  }

  tooltipEl.classList.add('chartjs-tooltip')

  // Set Text
  if (tooltipModel.dataPoints.length) {
    const { xLabel } = tooltipModel.dataPoints[0]
    const dataPoint = scheduleInfo[xLabel]
    const { backgroundColor } = tooltipModel.labelColors[0]
    const title = tooltipModel.body[0].lines[0].split(':')[0]
    const tooltip = tooltipTemplate(dataPoint, { title, backgroundColor })

    tooltipEl.innerHTML = tooltip
  }

  // `this` will be the overall tooltip
  var position = this._chart.canvas.getBoundingClientRect()

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1
  tooltipEl.style.position = 'absolute'
  tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px'
  tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px'
  tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px'
  tooltipEl.style.width = 'max-content'
  tooltipEl.style.pointerEvents = 'none'
}

function tooltipTemplate({ age, dap, ldap, start_year_market_value, aha_repayment, withdrawal_limit, year }, { title, backgroundColor }) {
  return `
    <div class="schedule-tooltip">
      <div class="schedule-tooltip__title">
        <span style="background-color: ${backgroundColor};"></span>
        ${title}
      </div>
      <div class="schedule-tooltip__header">
        <div>
          ${currency((+dap + +ldap))}
          <span>Assistance Payment</span>
        </div>
        <div>
          ${currency(start_year_market_value)}
          <span>Market value</span>
        </div>
      </div>
      <div class="schedule-tooltip__body">
        <div>
          ${year}
          <span>Year</span>
        </div>
        <div>
          ${+aha_repayment > 0 ?
            `<span style="color: var(--color-red);">${currency(aha_repayment)}</span>`
            :
            currency(aha_repayment)
          }
          <span>Withdrawal Penalty</span>
        </div>
        <div>
          ${age}
          <span>Age</span>
        </div>
        <div>
        </div>
      </div>
    </div>
  `
}

export function initWithdrawalChart(nodeId, { data: initData, datasetsStyles }) {
  if (!initData.datasets.length) return

  const ctx = document.getElementById(nodeId).getContext('2d')
  if (!ctx) return

  const type = 'line'
  const options = baseOptions()
  const datasets = styleChartDatasets(initData.datasets, datasetsStyles(ctx))
  const data = { ...initData, datasets }

  return new Chart(ctx, { type, data, options })
}

const styleChartDatasets = (datasets, datasetsStyles) => datasets.map((item, index) => ({
  ...item,
  ...datasetsStyles && datasetsStyles[index % datasetsStyles.length]
}))
