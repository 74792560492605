global.modalParams = new Map();

global.initModal = function (selector, html, lockBodyScroll = true) {
  const $modalWrapper = $(selector);

  if (lockBodyScroll) lockScroll();
  if (html) $modalWrapper.html(html);

  const $modal = $modalWrapper.find('.modal');
  const $modalCloseButton = $modal.find('.modal__close, .modal__overlay');

  setTimeout(function () {
    $modal.addClass('modal--open');
  }, 100);

  $modalCloseButton.each(function () {
    this.onclick = () => closeModal(selector, lockBodyScroll);
  })

  cancelButtonInit();

  return $modal
}

global.updateModalBody = function (selector, html) {
  const $modalWrapper = $(selector);
  const $modal = $modalWrapper.find('.modal');
  const $modalBody = $modalWrapper.find('.modal__body');

  $modalBody.html(html);
  cancelButtonInit();

  return $modal
}

global.cancelButtonInit = function () {
  $('[onclick^="closeModal"]').on('click', e => e.preventDefault());
}

global.closeModal = function (selector, lockBodyScroll = true) {
  const $modal = selector ? $(selector).find('.modal') : $('.modal--open');

  $modal.removeClass('modal--open');
  if (lockBodyScroll) unlockScroll();
  if ($modal.hasClass('modal--video')) $modal.html('');
}

global.lockScroll = function () {
  if (document.body.style.position === 'fixed') return;
  document.body.style.top = `-${window.scrollY}px`;
  document.body.style.position = 'fixed';
}

global.unlockScroll = function () {
  if ($('.modal--open').length) return;
  const scrollY = -parseInt(document.body.style.top);

  document.body.style.position = '';
  document.body.style.top = '';
  window.scrollTo(0, scrollY);
}
