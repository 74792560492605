const currency = number => {
  const mobile = window.innerWidth < 800;
  const arrayOfChars = new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' })
    .formatToParts(number)
    .map(({type, value}) => {
      switch (type) {
        case 'currency': return value;
        case 'decimal': return '';
        case 'fraction': return '';
        default : return value;
      }
    });
  const sign = arrayOfChars.shift();
  const formattedNumber = arrayOfChars.reduce((string, part) => string + part);
  return `${sign}${formattedNumber}`;
}

Chart.defaults.line.spanGaps = false
Chart.defaults.global.defaultFontFamily = 'Open Sans'
Chart.defaults.global.defaultFontColor = '#8C9AA3'
Chart.defaults.global.defaultFontSize = 14
Chart.defaults.global.defaultFontStyle = 400

export const colors = [
  'rgba(64, 131, 91)',
  'rgba(64, 131, 91)',
  'rgba(16, 31, 90)',
  'rgba(16, 31, 90)',
]
export const fillColorsTop = [
  'rgba(64, 131, 91, .2)',
  'rgba(64, 131, 91, .25)',
  'rgba(16, 31, 90, 0.2)',
  'rgba(16, 31, 90, 0.25)',
]
export const fillColorsBottom = [
  'rgba(64, 131, 91, 0.01)',
  'rgba(64, 131, 91, 0.06)',
  'rgba(16, 31, 90, 0.01)',
  'rgba(16, 31, 90, 0.06)',
]
const baseOptions = () => ({
  legendCallback: initLegend,
  legend: { display: false },
  hover: { intersect: false },
  maintainAspectRatio: false,
  tooltips: {
    position: 'nearest',
    xPadding: 20,
    yPadding: 10,
    enabled: false,
    custom: customTooltip,
  },
  scales: {
    xAxes: [{
      gridLines: { display: false },
      ticks: {
        autoSkip: true,
        autoSkipPadding: 20,
        maxRotation: 0,
        padding: 10,
        min: 0,
        maxTicksLimit: 5,
      }
    }],
    yAxes: [{
      gridLines: {
        color: '#E7E7E7',
        zeroLineColor: '#E7E7E7',
        drawBorder: false,
        drawTicks: false
      },
      ticks: {
        beginAtZero: 0,
        maxTicksLimit: 5,
        padding: 15,
        callback: number => currency(number)
      }
    }]
  },
  onClick: function (event) {
    const { _index, _datasetIndex, _chart } = schedulesChart.getElementAtEvent(event)[0] || {}
    const age = _chart?.config?.data?.labels?.[_index]
    const { url } = schedulesInfo?.[_datasetIndex]?.[age] || {}

    global.changeContribution(url)
  },
  plugins: {
    crosshair: {
      enabled: true,
      color: fillColorsTop[1],
      width: 1,
      dashPattern: [2, 2],
      sync: {
        suppressTooltips: true,
        enabled: false
      },
      zoom: { enabled: false },
      snap: { enabled: true },
    }
  }
})

function customTooltip(tooltipModel) {
  var tooltipEl = document.getElementById('chartjs-tooltip')

  // Create element on first render
  if (!tooltipEl) {
    tooltipEl = document.createElement('div')
    tooltipEl.id = 'chartjs-tooltip'
    document.body.appendChild(tooltipEl)
  }

  // Hide if no tooltip
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = 0
    return
  }

  // Set caret Position
  tooltipEl.classList.remove(...tooltipEl.classList.values())
  if (tooltipModel.yAlign || tooltipModel.xAlign) {
    if (tooltipModel.yAlign) tooltipEl.classList.add(tooltipModel.yAlign)
    if (tooltipModel.xAlign) tooltipEl.classList.add(tooltipModel.xAlign)
  } else {
    tooltipEl.classList.add('no-transform')
  }

  tooltipEl.classList.add('chartjs-tooltip')

  // Set Text
  if (tooltipModel.dataPoints.length) {
    const { datasetIndex, xLabel } = tooltipModel.dataPoints[0]
    const dataPoint = schedulesInfo?.[datasetIndex]?.[xLabel]
    const { backgroundColor } = tooltipModel.labelColors[0]
    const title = tooltipModel.body[0].lines[0].split(':')[0]
    const tooltip = tooltipTemplate(dataPoint, { title, backgroundColor })

    tooltipEl.innerHTML = tooltip
  }

  // `this` will be the overall tooltip
  var position = this._chart.canvas.getBoundingClientRect()

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1
  tooltipEl.style.position = 'absolute'
  tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px'
  tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px'
  tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px'
  tooltipEl.style.width = 'max-content'
  tooltipEl.style.pointerEvents = 'none'
}

function tooltipTemplate({ age, bond, grant, start_year_market_value, required_contribution, year }, { title, backgroundColor }) {
  return `
    <div class="schedule-tooltip">
      <div class="schedule-tooltip__title">
        <span style="background-color: ${backgroundColor};"></span>
        ${title}
      </div>
      <div class="schedule-tooltip__header">
        <div>
          ${currency(required_contribution)}
          <span>Contribution</span>
        </div>
        <div>
          ${currency(start_year_market_value)}
          <span>Market value</span>
        </div>
      </div>
      <div class="schedule-tooltip__body">
        <div>
          ${year}
          <span>Year</span>
        </div>
        <div>
          ${currency(grant)}
          <span>Grant</span>
        </div>
        <div>
          ${age}
          <span>Age</span>
        </div>
        <div>
          ${currency(bond)}
          <span>Bond</span>
        </div>
      </div>
    </div>
  `
}

export function initChart(nodeId, { data: initData, datasetsStyles }) {
  if (!initData.datasets.length) return

  const ctx = document.getElementById(nodeId).getContext('2d')
  if (!ctx) return

  const type = 'line'
  const options = baseOptions()
  const datasets = styleChartDatasets(initData.datasets, datasetsStyles(ctx))
  const data = { ...initData, datasets }

  return new Chart(ctx, { type, data, options })
}

export function updateChartDatasets(chart, { data, datasetsStyles }) {
  if (!data.datasets.length) return

  chart.data.datasets = styleChartDatasets(data.datasets, datasetsStyles(ctx))
  chart.data.labels = data.labels

  chart.update()
}

const styleChartDatasets = (datasets, datasetsStyles) => datasets.map((item, index) => ({
  ...item,
  ...datasetsStyles && datasetsStyles[index % datasetsStyles.length]
}))

export function initLegend(chart) {
  const hiddenClass = 'legend-item--hidden';
  const legend = document.getElementById('legend');

  chart.legend.legendItems.forEach(({ datasetIndex }) => {
    const meta = chart.getDatasetMeta(datasetIndex);
    const legendItem = legend.querySelector(`[data-dataset-index="${datasetIndex}"]`);
    const { borderColor } = meta.dataset._view;

    if(legendItem) {
      legendItem.querySelector('.legend-item__indicator').style.backgroundColor = borderColor;
      legendItem.onclick = toggleMetaHidden;
    }

    function toggleMetaHidden() {
      meta.hidden = !meta.hidden;
      chart.update();

      if (this.classList.contains(hiddenClass)) this.classList.remove(hiddenClass)
      else this.classList.add(hiddenClass)
    }
  })
}

global.chartsDestroy = function () {
  const chartInstances = Object.values(Chart.instances)
  if (!chartInstances.length) return

  chartInstances.forEach(chart => chart.destroy())
}
