$(document).on('ready turbolinks:load', () => initSelect2());

const showSearchFor = `
  #account_year_of_birth_1i,
  #account_dtc_entitlement_year_1i,
  #account_account_opened_year_1i
`

global.initSelect2 = function (selector = null, options = {}) {
  const $forms = $(selector || 'form');
  const $selects = $forms.find('select');
  const $selectsWithSearch = $(showSearchFor)

  $selects.each(function () {
    const props = this.dataset;
    const hasSearch = [...$selectsWithSearch].some(item => item === this);

    $(this).select2({
      allowClear: false,
      ...!hasSearch && { minimumResultsForSearch: -1 },
      ...options,
      ...props,
    });
  });

  $('.select2-selection.select2-selection--single').on('focus', function () {
    $(this).closest('.select2-container').siblings('select:enabled').select2('open');
  });

  $('select').on('select2:closing', e => $(e.target).data('select2').$selection.one('focus focusin', e => e.stopPropagation()));
}
