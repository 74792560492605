import Turbolinks from 'turbolinks'
Turbolinks.start()
Turbolinks.setProgressBarDelay(0)

global.Rails = require('@rails/ujs')
Rails.start()

global.Activestorage = require('@rails/activestorage')
Activestorage.start()

global.$ = $
global.jQuery = jQuery

require('channels')
import 'cocoon'

global.toastr = require('toastr/toastr')
global.select2 = require('select2/dist/js/select2.full')
global.Dropdown = require('bootstrap/js/dist/dropdown')
import 'bootstrap/js/dist/tooltip'
import 'chart.js'
import 'popper.js'
import 'chartjs-plugin-crosshair/dist/chartjs-plugin-crosshair'
import 'sticky-kit/dist/sticky-kit'

import 'javascripts/ui/select2'
import 'javascripts/ui/flash'
import 'javascripts/ui/modal'
import 'javascripts/ui/chart'

import 'javascripts/account'
import 'javascripts/general'
import 'javascripts/charts'
import 'javascripts/withdrawal_limits'
