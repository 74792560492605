const currency = number => new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(number).replace(/\.\d+/, '')

$(document).on('ready turbolinks:load', function() {
  $('body').on('change', '#ldap-year-selector', function () {
    const defaultYear = $(this).data('default-value');
    const selectedYear = $(this).val();

    if (+defaultYear !== +selectedYear) $('#submit-ldap').removeClass('hidden');
    else $('#submit-ldap').addClass('hidden');
  });

  $('body').on('click', '#add-dap', function () {
    toggleDapButtonsVisibility();
  });

  $('body').on('click', '.remove-dap-fake-button', function () {
    if (+$(this).data('persisted')) {
      const destroyUrl = $(this).data('destroy-url');
      $('a#confirm-destroy-payment-btn').attr('href', destroyUrl);
      initModal('#destroy-payment-modal');
    } else {
      $(this).siblings('.remove-dap-button').trigger('click');
      toggleDapButtonsVisibility();
    }
  });

  $('body').on('click', '#cancel-destroy-payment-btn', function () {
    closeModal('#destroy-payment-modal');
  });

  $('body').on('change keyup', '.dap-input', function () {
    const $amountSelector = $(this).closest('.dap-item').find('.dap-amount-field');
    const amount = $amountSelector.val();
    const yob = $amountSelector.data('yob');
    const selectedYear = $(this).closest('.dap-item').find('.assistance-payment-year-selector').val();

    if (!selectedYear || !amount) return;

    const age = +selectedYear - +yob -1;
    const { withdrawal_limit: limit } = scheduleInfo[age];

    if (+amount <= +limit) {
      $amountSelector.removeClass('is-invalid');
      $amountSelector.closest('.form-group').find('.error-message').remove();
      $('#submit-dap').attr('disabled', false);
    } else {
      $amountSelector.addClass('is-invalid');
      $amountSelector.closest('.form-group').append(`<div class="error-message">should be less than or equal to ${currency(limit)}</div>`);
      $('#submit-dap').attr('disabled', true);
    }
  });
});

global.warnAboutLimit = function ({ paymentsNet, inputSelector, warningSelector, parentContainer }) {
  $('body').on('change', inputSelector, function () {
    const year = $(this).val();
    const limit = currency(paymentsNet[year]['withdrawal_limit']);

    $(this).closest(parentContainer).find(warningSelector).html(limit);
  });
}

global.toggleDapButtonsVisibility = () => {
  const $addDapButton = $('#add-dap');
  const $saveDapButton = $('#submit-dap');

  $saveDapButton.toggleClass('hidden');
  $addDapButton.toggleClass('hidden');
};
