// // This file is automatically compiled by Webpack, along with any other files
// // present in this directory. You're encouraged to place your actual application logic in
// // a relevant structure within app/javascript and only use these pack files to reference
// // that code so it'll be compiled.

// import 'bootstrap'
// import Turbolinks from 'turbolinks'
// import * as ActiveStorage from '@rails/activestorage'
// global.Rails = require('@rails/ujs')

// ActiveStorage.start()
// Turbolinks.start()
// Turbolinks.setProgressBarDelay(0)
// Rails.start()

// global.$ = $
// global.jQuery = jQuery
// // global.broadcasts = {}

// require("channels")
// require("chart.js")
// require("Chart.extension.js")
// require("jquery-scrollLock.min.js")
// require("jquery.scrollbar.min.js")
// require("argon.js")
// require("select2")
// import("select2/dist/css/select2.css")
// import("select2-bootstrap4-theme/dist/select2-bootstrap4.css")

// import * as Selector from './components/select2'
// import './components/account'

// global.initSelect2 = Selector.initSelect2

// // Uncomment to copy all static images under ../images to the output folder and reference
// // them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// // or the `imagePath` JavaScript helper below.
// //
// // const images = require.context('../images', true)
// // const imagePath = (name) => images(name, true)


require.context('../images', true);
import './style'
import './index'
