import { initChart, updateChartDatasets, colors, fillColorsTop, fillColorsBottom } from './ui/chart'
import { initWithdrawalChart } from './ui/withdrawal_chart'
import waitingEndAcceleratedSrc from 'images/ui/waiting-end-point--accelerated.svg'
import waitingEndModifiedSrc from 'images/ui/waiting-end-point--modified.svg'

const waitingEndSrcs = [
  waitingEndAcceleratedSrc,
  waitingEndAcceleratedSrc,
  waitingEndModifiedSrc,
  waitingEndModifiedSrc,
]

const datasetsStyles = datasets => ctx => datasets.map((dataset, index) => {
  const color = colors[index]
  const gradient = ctx.createLinearGradient(0, 0, 0, 300)
  gradient.addColorStop(0, fillColorsTop[index])
  gradient.addColorStop(1, fillColorsBottom[index])

  const waitingEndPoint = !(index % 2) && dataset.data.filter(item => item != null).length - 1
  const pointsCount = !(index % 2) && dataset.data.length
  const pointStyle = pointsCount ? new Array(pointsCount).fill('circle') : 'circle'

  const waitingEndImage = new Image(26, 26)
  waitingEndImage.src = waitingEndSrcs[index]

  if (waitingEndPoint) pointStyle[waitingEndPoint] = waitingEndImage

  return {
    borderColor: color,
    borderWidth: 3,
    backgroundColor: gradient,
    pointBorderColor: 'white',
    pointBackgroundColor: color,
    pointHoverBackgroundColor: color,
    pointHoverBorderColor: 'white',
    pointRadius: 0,
    pointStyle,
    hoverRadius: 5,
    hitRadius: 6,
    pointBorderWidth: 2,
    pointHoverBorderWidth: 3,
    fill: true,
  }
})

global.initSchedulesChart = function (data) {
  global.schedulesChart = initChart('schedules-chart', { data, datasetsStyles: datasetsStyles(data.datasets) })
  schedulesChart.generateLegend()
}
global.initWithdrawalsChart = function (data) {
  global.withdrawalsChart = initWithdrawalChart('withdrawals-chart', { data, datasetsStyles: datasetsStyles(data.datasets) })
  withdrawalsChart.generateLegend()
}

global.updateSchedulesChartDatasets = function (chart, data) {
  updateChartDatasets(chart, { data, datasetsStyles })
  chart.generateLegend()
}
